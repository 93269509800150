<template>
  <div>
    <!--顶部工具条-->
    <el-col :span="24">
      <el-form :inline="true">
        <el-form-item label="关键字:">
          <el-input
            clearable
            @clear='clearContent'
            v-model="filters.name"
            placeholder="请输入昵称/登录名/手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="组织机构：" width>
          <el-cascader
            placeholder="请选择，支持搜索功能"
            style="width: 100%"
            v-model="OptionTreec"
            :options="options"
            :props="defaultProps"
            filterable
            change-on-select
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="类型:">
          <el-select v-model="filters.userType" clearable>
            <el-option
              v-for="item in userTypeArr"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="待处理:">
          <el-select v-model="filters.processed" clearable>
            <el-option
              v-for="item in processedArr"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="启用标识:">
          <el-select v-model="filters.selectc">
            <el-option
              v-for="item in optionsData"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
          ></toolbar>
        </el-form-item>
      </el-form>
    </el-col>
    <!--列表-->
    <el-table
      :data="users"
      highlight-current-row
      @current-change="selectCurrentRow"
      v-loading="listLoading"
      @selection-change="selsChange"
      style="width: 100%"
    >
      <!-- <el-table-column type="selection" width="50">
        </el-table-column> -->
      <el-table-column type="index" width="80"> </el-table-column>
      <el-table-column prop="Name" label="昵称" width="">
      </el-table-column>
      <el-table-column prop="LoginAccount" label="登录名" width="">
      </el-table-column>
      <el-table-column prop="PhoneNumber" label="手机号" width="">
      </el-table-column>
      <el-table-column
        prop="DepartmentName"
        label="组织机构"
        min-width="100"
      >
      </el-table-column>
      <el-table-column prop="RoleNames" label="角色" width="" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-tag v-for="item in scope.row.RoleNames" :key="item.Id">{{
            item
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="UserType" label="类型" align="center" width="">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.UserType == 1 ? '' : 'success'"
            disable-transitions
            >{{ scope.row.UserType == 1 ? "系统账号" : "对接账号" }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="IsInit" label="是否待处理" align="center" min-width="100">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.IsInit == true ? 'danger' : 'success'"
            disable-transitions
            >{{ scope.row.IsInit == true ? "否" : "是" }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="IsEnable" label="启用标识" min-width="100">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.IsEnable == true ? 'success' : 'danger'"
            disable-transitions
            >{{ scope.row.IsEnable == true ? "启用" : "禁用" }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="CreateTime" label="创建时间" min-width="110" sortable></el-table-column>
    </el-table>
    <!--底部工具条-->
    <el-col :span="24" class="pageBar">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.pageIndex"
        :page-sizes="pages.pageArr"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.dataCount"
      >
      </el-pagination>
    </el-col>

    <!--新增-->
    <el-dialog
      title="新增"
      :visible.sync="addFormVisible"
      v-model="addFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        :model="addForm"
        label-width="130px"
        :rules="addFormRules"
        ref="addForm"
      >
        <el-form-item label="昵称:" prop="Name">
          <el-input v-model="addForm.Name" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="登录名:" prop="LoginAccount">
          <el-input
            :value="addForm.LoginAccount"
            @input="(e) => (addForm.LoginAccount = validForbid(e))"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="短信验证手机号:" prop="PhoneNumber">
          <el-input
            v-model="addForm.PhoneNumber"
            onkeyup="value=value.replace(/[^\d]/g,'-')"
            maxlength="15"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="组织机构:" prop="OptionTree">
          <el-cascader
            placeholder="请选择，支持搜索功能"
            style="width: 100%"
            v-model="addForm.OptionTree"
            :options="options"
            :props="defaultProps"
            filterable
            change-on-select
            ref="addFormOptionRef"
            @change="addFormOptionChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="角色:" prop="RoleIds">
          <el-select
            multiple
            v-model="addForm.RoleIds"
            placeholder="请先选择组织机构"
          >
            <!-- <el-option  :key="0" :label="'未选择角色'" :value="0"></el-option> -->
            <el-option
              v-for="item in roles"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否启用:" prop="IsEnable">
          <el-select v-model="addForm.IsEnable" placeholder="请选择是否启用">
            <el-option
              v-for="item in optionsData"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input type="textarea" v-model="addForm.Remarks"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="addFormVisible = false">取消</el-button>
        <el-button
          type="primary"
          @click.native="addSubmit"
          :loading="addLoading"
          >提交</el-button
        >
      </div>
    </el-dialog>
    <!--编辑-->
    <el-dialog
      title="编辑"
      :visible.sync="editFormVisible"
      v-model="editFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        :model="editForm"
        label-width="130px"
        :rules="editFormRules"
        ref="editForm"
      >
        <el-form-item label="昵称:" prop="Name">
          <el-input v-model="editForm.Name" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="登录名:" prop="LoginAccount">
          <el-input
            :value="editForm.LoginAccount"
            @input="(e) => (editForm.LoginAccount = validForbid(e))"
            auto-complete="off"
            :disabled='true'
          ></el-input>
        </el-form-item>
        <el-form-item label="短信验证手机号:" prop="PhoneNumber">
          <el-input
            v-model="editForm.PhoneNumber"
            maxlength="11"
            onkeyup="value=value.replace(/[^\d]/g,'')"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="组织机构:">
          <el-cascader
            placeholder="请选择，支持搜索功能"
            style="width: 100%"
            v-model="editForm.OptionTree"
            :options="options"
            :props="defaultProps"
            filterable
            change-on-select
            ref="editFormOptionRef"
            @change="addFormOptionChange1"
          ></el-cascader>
        </el-form-item>

        <el-form-item label="角色:" prop="RoleIds">
          <el-select
            multiple
            v-model="editForm.RoleIds"
            placeholder="请选择角色"
          >
            <!-- <el-option  :key="0" :label="'未选择角色'" :value="0"></el-option> -->
            <el-option
              v-for="item in roles"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否启用:" prop="IsEnable">
          <el-select v-model="editForm.IsEnable" placeholder="请选择是否启用">
            <el-option
              v-for="item in optionsData"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input type="textarea" v-model="editForm.Remarks"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="editFormVisible = false">取消</el-button>
        <el-button
          type="primary"
          @click.native="editSubmit"
          :loading="editLoading"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import util from "../../../util/date";
import { validPhone } from "../../../util/validate";
import {
  getUserListPage,
  updateUserInfo,
  addUserInfo,
  deleteUserInfo,
  getOrganizationTree,
  getRoleInfosByOrganizationInfoID,
} from "../../api/api";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from "qs";
export default {
  components: { Toolbar },
  data() {
    //验证手机号
    let isPhoneNumberNum = (rule, value, callback) => {
      if (!validPhone(value)) {
        return callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    return {
      users: [],
      roles: [],
      listLoading: false,
      currentRow: null,
      //列表选中列
      sels: [],
      //增删改查按钮
      buttonList: [],
      //顶部筛选条件
      filters: {
        name: "",
        selectc: true,
        userType: "",
        processed: "",
      },
      //关于分页的obj
      pages: {
        pageSize: 20, //默认每页条数
        pageArr: [10, 20, 30, 40], //默认每页条数选择
        pageIndex: 1, //默认进入页
        dataCount: 20, //默认总页数
      },

      //新增界面是否显示
      addFormVisible: false,
      addLoading: false,
      addFormRules: {
        LoginAccount: [
          { required: true, message: "请输入登录名", trigger: "blur" },
        ],
        Name: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        PhoneNumber: [
          { required: true, message: "不能为空", trigger: "blur" },
          { validator: isPhoneNumberNum },
        ],
        OptionTree: [
          { required: true, message: "请选择组织机构", trigger: "blur" },
        ],
      },
      //dialog数据
      addForm: {
        Name: "",
        LoginAccount: "",
        RoleIds: [],
        OptionTree: [],
        OrganizationInfoID: "",
        ID: "",
        IsEnable: true,
        PhoneNumber: "",
      },
      //编辑界面是否显示
      editFormVisible: false,
      editLoading: false,
      editFormRules: {
        LoginAccount: [
          { required: true, message: "请输入登录名", trigger: "blur" },
        ],
        Name: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        PhoneNumber: [
          { required: true, message: "不能为空", trigger: "blur" },
          { validator: isPhoneNumberNum },
        ],
        OptionTree: [
          { required: true, message: "请选择组织机构", trigger: "blur" },
        ],
      },
      //dialog数据
      editForm: {
        Name: "",
        LoginAccount: "",
        RoleIds: [],
        OptionTree: [],
        OrganizationInfoID: "",
        ID: "",
        IsEnable: "",
        PhoneNumber: "",
      },
      //是否启用状态
      optionsData: [
        {
          value: true,
          name: "启用",
        },
        {
          value: false,
          name: "禁用",
        },
      ],
      // 类型
      userTypeArr: [
        {value: 1,name: "系统账号"},
        {value: 2,name: "对接账号"},
      ],
      // 待处理
      processedArr: [
        {value: false,name: "是"},
        {value: true,name: "否"},
      ],
      // 组织结构树数据
      defaultProps: {
        children: "Children",
        label: "Name",
        value: "Id", //可要可不要
      },

      OptionTreec: [],
      options: [],
    };
  },
  methods: {
    // 清空筛选框内容，重新获取数据
    clearContent(){
      this.getData()
    },
    //当前行发生变化时
    selectCurrentRow(val) {
      this.currentRow = val;
	    console.log(val,'val')
    },
    //选项发生变化时
    selsChange: function (sels) {
      this.sels = sels;
    },
    //显示新增界面
    handleAdd() {
      this.addForm = {
        Name: "",
        LoginAccount: "",
        IsEnable: true,
        OrganizationInfoID: 0,
        RoleIds: [],
        ID: JSON.parse(localStorage.getItem("user")).ID,
        isDeleted: true,
      };
      this.roles = [];
      this.addFormVisible = true;
    },
    //显示编辑界面
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      this.editForm = Object.assign({}, row);
      this.editForm.OptionTree = row.DepartmentId
      this.getRolesFun(this.editForm.OrganizationInfoID);
      this.editFormVisible = true;
    },
    //新增
    addSubmit: function () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.addLoading = true;
            let params = Object.assign({}, this.addForm);
            // params.OrganizationInfoID = this.addForm.OptionTree.pop()
            params.DepartmentId = this.addForm.OptionTree[
              this.addForm.OptionTree.length - 1
            ];
            addUserInfo(params).then((res) => {
              if (util.isEmt.format(res)) {
                this.addLoading = false;
                return;
              }

              if (res.data.Success) {
                this.addLoading = false;
                this.$message({
                  message: res.data.Message,
                  type: "success",
                });
                this.addFormVisible = false;
                this.$refs["addForm"].resetFields();
                this.getData();
              } else {
                this.addLoading = false;
                this.$message({
                  message: res.data.Message,
                  type: "error",
                });
              }
            });
          });
        }
      });
    },
    //编辑
    editSubmit: function () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.editLoading = true;
            let params = Object.assign({}, this.editForm);
            if(this.editForm.OptionTree instanceof Array) {
              params.DepartmentId = this.editForm.OptionTree[
                this.editForm.OptionTree.length - 1
              ];
            } else {
              params.DepartmentId = this.editForm.OptionTree
            }
            updateUserInfo(params).then((res) => {
              if (util.isEmt.format(res)) {
                this.editLoading = false;
                return;
              }
              if (res.data.Success) {
                this.editLoading = false;
                this.$message({
                  message: res.data.Message,
                  type: "success",
                });
                this.editFormVisible = false;
                this.getData();
              } else {
                this.editLoading = false;
                this.$message({
                  message: res.data.Message,
                  type: "error",
                });
              }
            });
          });
        }
      });
    },
    //删除
    handleDel() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要删除的一行数据！",
          type: "error",
        });

        return;
      }
      this.$confirm("确认删除该用户吗?", "提示", {
        type: "warning",
      })
        .then(() => {
          this.listLoading = true;
          let params = { id: row.Id };
          deleteUserInfo(params).then((res) => {
            if (util.isEmt.format(res)) {
              this.listLoading = false;
              return;
            }

            if (res.data.Success) {
              this.listLoading = false;
              this.$message({
                message: res.data.Message,
                type: "success",
              });
              this.userFormVisible = false;
              this.getData();
            } else {
              this.listLoading = false;
              this.$message({
                message: res.data.Message,
                type: "error",
              });
            }
          });
        })
        .catch(() => {});
    },
    // 查询
    getUsers(){
      this.pages.pageIndex = 1
      this.getData()
    },
    //获取用户列表
    getData() {
      this.listLoading = true;
      var params = {
          pageIndex: this.pages.pageIndex,
          pageSize: this.pages.pageSize,
          userName: this.filters.name,
          isEnable: this.filters.selectc,
          userType: this.filters.userType,
          isInit: this.filters.processed,
      };  
      if (this.OptionTreec.length > 0) {
        params.departmentInfoId = this.OptionTreec[
          this.OptionTreec.length - 1
        ];
      }
      getUserListPage(params).then((res) => {
        this.users = res.data.Response.Data;
        this.listLoading = false;

        this.pages.dataCount = res.data.Response.DataCount;
        this.pages.pageIndex = res.data.Response.PageIndex;
        if (res.data.Response.PageSize > 0) {
          this.pages.pageSize = res.data.Response.PageSize;
        }
      });
    },
    // 获取树结构数据
    getOrganizations() {
      var _this = this;
      var ID = JSON.parse(localStorage.getItem("user")).ID;
      var params = {
        parentId: 0,
      };
      getOrganizationTree(params).then((res) => {
        var data = res.data;
        var resData = [data.Response];
        if (data.Success) {
          if (data.Response.Name == "根节点") {
            _this.options = data.Response.Children;
          } else {
            _this.options = resData;
          }
        }
      });
    },
    //根据组织结构获取角色列表
    addFormOptionChange() {
      if (this.$refs["addFormOptionRef"].checkedValue) {
        if (this.$refs["addFormOptionRef"].checkedValue.length > 0) {
          // this.getRolesFun(this.$refs['addFormOptionRef'].checkedValue.pop())
          this.addForm.RoleIds = []
          this.getRolesFun(
            this.$refs["addFormOptionRef"].checkedValue[
              this.$refs["addFormOptionRef"].checkedValue.length - 1
            ]
          );
        }
      }
    },
    addFormOptionChange1() {
      if (this.$refs["editFormOptionRef"].checkedValue) {
        if (this.$refs["editFormOptionRef"].checkedValue.length > 0) {
          // this.getRolesFun(this.$refs['editFormOptionRef'].checkedValue.pop())
          this.editForm.RoleIds = []
          this.getRolesFun(
            this.$refs["editFormOptionRef"].checkedValue[
              this.$refs["editFormOptionRef"].checkedValue.length - 1
            ]
          );
        }
      }
    },
    getRolesFun(rolesid) {
      var params = {
        // organizationInfoId: rolesid,
      };
      getRoleInfosByOrganizationInfoID(params).then((res) => {
        this.roles = res.data.Response;
      });
    },

    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    // 分页
    handleCurrentChange(val) {
      // if(this.filters.name != '' || this.filters.selectc != false){
      //   this.pages.pageIndex = 1
      // }else{
        this.pages.pageIndex = val;
      // }      
      this.getData();
    },
    handleSizeChange(val) {
      this.pages.pageSize = val;     
      this.getData();
    },
    validForbid(value, number = 2550) {
      value = value
        .replace(
          /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
          ""
        )
        .replace(/\s/g, "");
      if (value.length >= number) {
        this.$message({
          type: "warning",
          message: `输入内容不能超过${number}个字符`,
        });
      }
      return value;
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers);
    this.getOrganizations();
  },
};
</script>
<style lang="stylus" scoped>
.rolesTop {
  width: 100%;
  display: flex;
}

.rolesInp {
  width: 300px;
  margin-right: 20px;
}

.pageBar {
  margin-top: 15px;
}
</style>
